import PropTypes from 'prop-types'
import React from 'react'
import { Columns } from 'react-bulma-components'

function TheStrategy({ data }) {
  return (
    <Columns.Column className="is-offset-1 is-6 is-size-4 mb-5">
      <div
        dangerouslySetInnerHTML={{
          __html: data.strategy.summaryNode.childMarkdownRemark.html,
        }}
      />
      <h2>The Strategy</h2>
      <p className="is-size-5">
        The canvas below attempts and convey the essential parts of the{' '}
        {data.strategy.brand.name} mobile strategy. It is based on our own data
        sets, research and what is in the public domain. Disclaimer - this is
        our opinion, not that of {data.strategy.brand.name}.
      </p>
    </Columns.Column>
  )
}
TheStrategy.propTypes = {
  data: PropTypes.object,
}
export default TheStrategy
