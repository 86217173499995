import React from 'react'
import { Link } from 'gatsby'
import { Columns } from 'react-bulma-components'

function BookWorkshop() {
  return (
    <Columns.Column className="is-4-desktop is-offset-1-tablet is-12-mobile is-10-tablet">
      <div className="has-background-white-bis p-6 is-size-6 mb-5">
        <h3 className="is-size-3 mt-0 pt-0 ">
          Need help figuring out{' '}
          <span className="has-text-primary">your strategy?</span>
        </h3>
        <p className="is-size-6">
          We can help you hone your own mobile strategy and develop a winning
          app. We'll take your leadership team through a proven strategic
          process.
        </p>

        <p className="">
          <a
            className="button is-primary is-leafy is-medium mb-3"
            target="_blank"
            href="https://calendly.com/anna-pw/strategy-workshop?month=2022-07"
            rel="noreferrer"
          >
            Book a Strategy Workshop!
          </a>
          <br />
          <Link to="/services/strategy" className="is-size-6 has-text-grey">
            Learn more about our services
          </Link>
        </p>
      </div>
    </Columns.Column>
  )
}

export default BookWorkshop
