import React from 'react'
import { Link } from 'gatsby'
import { Columns } from 'react-bulma-components'

function Disclaimer() {
  return (
    <Columns.Column className="p-6 is-5 is-hidden-mobile ">
      <p className="is-size-6 has-text-grey">
        <i className="fa-solid fa-circle-info" />
        &nbsp;Pioneered by the world leading business strategist, Graham Martin,
        the Strategy Choice Cascade shown here is a useful way of communicating
        strategic choices.
      </p>
      <ul className="is-size-6 pl-5">
        <li>
          <Link to="/resources/strategy-choice-cascade/">
            Build your own with this template
          </Link>
        </li>
        <li>
          <Link to="/resources/mobile-strategy-process/">
            Learn about the process
          </Link>
        </li>
        <li>
          <Link to="/contact">Get some advice to shape your strategy</Link>
        </li>
      </ul>
    </Columns.Column>
  )
}

export default Disclaimer
