import PropTypes from 'prop-types'
import React from 'react'
import { Columns } from 'react-bulma-components'

// Components
import ArrowLink from 'components/link-with-arrow'
import StrategySubscribeComponent from 'components/strategy-subscribe'

function Commentary({ data }) {
  return (
    <Columns.Column className="is-6-desktop is-10-tablet pl-5">
      <h1 className="has-text-left">
        What can you learn from {data.strategy.brand.name}?
      </h1>
      <div
        dangerouslySetInnerHTML={{
          __html: data.strategy.commentaryNode.childMarkdownRemark.html,
        }}
      />

      <div className="content has-background-white-bis p-6 is-size-6 mt-5 ">
        <h3 className="is-size-3 mt-0 pt-0 pb-0 mb-0">
          Get more inspiring strategies,{' '}
          <span className="has-text-primary">straight to your inbox.</span>
        </h3>
        <span className="is-size-6 has-text-grey">
          Learn what works, every month.
        </span>
        <StrategySubscribeComponent />
      </div>

      <p className="mt-5">
        <ArrowLink to="/services/strategy">
          Create your own mobile strategy
        </ArrowLink>
        <br />
        <ArrowLink to="/mobile-strategy-toolkit">
          Mobile Tools &amp; Resources
        </ArrowLink>
        <br />
        <ArrowLink to="/about/story">About Pocketworks</ArrowLink>
        <br />
        <ArrowLink to="/contact">Contact us</ArrowLink>
      </p>
    </Columns.Column>
  )
}
Commentary.propTypes = {
  data: PropTypes.object,
}
export default Commentary
