/* eslint-disable react/prop-types */
import React from 'react'

import { graphql } from 'gatsby'

// Meta Component
import Meta from 'components/Meta'

// StrategyCascade Route
import StrategyCascade from 'routes/templates/strategy-cascade'

// Gatsby SEO Head
export function Head({ data }) {
  return (
    <Meta tags={data.strategy.seoMetaTags.tags} hideMeta hideTitle>
      <title>{data.strategy.headline}</title>
      <meta name="description" content={data.strategy.summary} />
    </Meta>
  )
}

export default StrategyCascade

export const query = graphql`
  query StrategyQuery($slug: String!) {
    strategy: datoCmsStrategyCascade(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      headline
      summaryNode {
        childMarkdownRemark {
          html
        }
      }
      winningAspirationNode {
        childMarkdownRemark {
          html
        }
      }
      whereToPlayGeographyNode {
        childMarkdownRemark {
          html
        }
      }
      whereToPlayChannelNode {
        childMarkdownRemark {
          html
        }
      }
      whereToPlayCustomersNode {
        childMarkdownRemark {
          html
        }
      }
      whereToPlayOfferingNode {
        childMarkdownRemark {
          html
        }
      }
      howToWinNode {
        childMarkdownRemark {
          html
        }
      }
      commentaryNode {
        childMarkdownRemark {
          html
        }
      }
      brand {
        name
        logoDark {
          gatsbyImageData(
            placeholder: TRACED_SVG
            imgixParams: { fm: "png", auto: "compress", maxW: 600 }
          )
          fixedGatsbyImageData: gatsbyImageData(
            placeholder: TRACED_SVG
            imgixParams: { fm: "png", auto: "compress", h: "35" }
          )
          url
          fluid(maxWidth: 600, imgixParams: { fm: "png", auto: "compress" }) {
            ...GatsbyDatoCmsFluid_tracedSVG
          }
        }
      }
      heroBannerImage {
        fluid(
          maxWidth: 1800
          imgixParams: {
            fit: "crop"
            crop: "faces, focalpoint"
            fm: "jpg"
            auto: "compress"
          }
        ) {
          ...GatsbyDatoCmsFluid_tracedSVG
        }
        fixed(width: 1800, imgixParams: { fm: "png", auto: "compress" }) {
          ...GatsbyDatoCmsFixed
        }
      }
    }
  }
`
