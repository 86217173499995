import PropTypes from 'prop-types'
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Columns } from 'react-bulma-components'

class AskQuestion extends React.Component {
  componentDidMount() {
    // Handle show/hide modal events
    const el = document.querySelector('#ask-question-link')
    el.addEventListener('click', () => {
      document
        .querySelector('#modal-question-box')
        .classList.toggle('is-active')
    })
    document.querySelectorAll('.cancel-button').forEach((btn) => {
      btn.addEventListener('click', () => {
        document
          .querySelector('#modal-question-box')
          .classList.toggle('is-active')
      })
    })

    const handleSubmit = (e) => {
      console.log('Submitting')
      e.preventDefault()
      const myForm = document.getElementById('question-form')
      const formData = new FormData(myForm)
      document.querySelector('#form-submit-button').innerText = 'Sending...'
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          console.log('Form successfully submitted')
          document
            .querySelector('#modal-question-box')
            .classList.toggle('is-active')
        })
        .catch((error) => alert(error))
    }

    // Handle form submission
    document
      .querySelector('#question-form')
      .addEventListener('submit', handleSubmit)
  }

  render() {
    return (
      <div className="no-print">
        <Columns className="is-vcentered is-gapless is-flex">
          <Columns.Column className="is-narrow is-hidden-mobile is-hidden">
            <StaticImage
              src="https://www.datocms-assets.com/20955/1579120403-tobin.jpg?w=100&fit=facearea&facepad=3&mask=ellipse"
              alt="Tobin Harris - Pocketworks"
              className="mr-3"
              height={35}
            />
          </Columns.Column>
          <Columns.Column className="is-10 has-text-left ">
            <a
              className={`has-text-primary ${this.props.linkClassName}`}
              id="ask-question-link"
            >
              <i className="fa-solid fa-comment" /> Ask a question or give
              feedback
            </a>
          </Columns.Column>
        </Columns>

        <div className="modal has-text-left" id="modal-question-box">
          <div className="modal-background" />
          <div className="modal-card">
            <form
              name="question-form"
              id="question-form"
              method="POST"
              data-netlify="true"
              netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="question-form" />
              <input type="hidden" name="tag" value={this.props.tag} />

              <header className="modal-card-head is-hidden">
                <p className="modal-card-title">Submit</p>
                <button
                  type="button"
                  className="delete is-secondary cancel-button"
                  aria-label="close"
                />
              </header>
              <section className="modal-card-body">
                <h2>
                  Ask a question or send feedback,{' '}
                  <span className="has-text-success">
                    we'll respond as fast as we can.
                  </span>
                </h2>
                <Columns className="is-vcentered is-flex-mobile">
                  <Columns.Column className="is-2 is-hidden-mobile">
                    <StaticImage
                      src="https://www.datocms-assets.com/20955/1579120403-tobin.jpg?w=300&fit=facearea&facepad=4&mask=ellipse"
                      alt="Tobin Harris - Pocketworks"
                    />
                  </Columns.Column>
                  <Columns.Column>
                    <p>
                      This stuff is hard, so we're here to answer your
                      questions. Also, any feedback is hugely appreciated 🙌🏼{' '}
                    </p>
                  </Columns.Column>
                </Columns>

                <div className="field">
                  <div className="control">
                    <input
                      className="input is-success"
                      type="text"
                      name="name"
                      placeholder="Your first name*"
                      required
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="control">
                    <input
                      className="input is-success"
                      type="email"
                      name="email"
                      placeholder="Email address*"
                      required
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Your question or feedback*</label>
                  <div className="control">
                    <textarea className="textarea" name="question" required />
                  </div>
                </div>
                <label className="checkbox">
                  <input type="checkbox" name="wants-newsletter" />
                  &nbsp; Get notified of new stuff, you can unsubscribe anytime.
                </label>
              </section>
              <footer className="modal-card-foot">
                <button
                  className="button is-primary is-leafy is-medium"
                  id="form-submit-button"
                  type="submit"
                >
                  Ok, send it!
                </button>
                <button type="button" className="button cancel-button">
                  Cancel
                </button>
              </footer>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

AskQuestion.propTypes = {
  linkClassName: PropTypes.string,
  tag: PropTypes.string,
}

export default AskQuestion
