import PropTypes from 'prop-types'
import React from 'react'
import { Section, Container, Columns } from 'react-bulma-components'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'

// Page Components
import StrategyNav from './components/strategy-nav'
import StrategyImage from './components/strategy-image'
import TheStrategy from './components/the-strategy'
import Subscribe from './components/subscribe'
import WinningInspiration from './components/winning-inspiration'
import Disclaimer from './components/disclaimer'
import WhereToPlay from './components/where-to-play'
import Channel from './components/channel'
import HowToWin from './components/how-to-win'
import BookWorkshop from './components/book-workshop'
import Commentary from './components/commentary'
import Questions from './components/questions'

function StrategyCascade({ data }) {
  return (
    <Layout>
      <div className="case-study">
        <SiteNav fullLogo />
        <StrategyNav data={data} />
        <StrategyImage data={data} />
        <Section>
          <Container className="content">
            <Columns>
              <TheStrategy data={data} />
              <Subscribe />
            </Columns>
            <Columns>
              <Columns.Column className="is-offset-1 is-10 mt-6 bl">
                <Columns>
                  <WinningInspiration data={data} />
                  <Disclaimer />
                  <WhereToPlay data={data} />
                  <Channel data={data} />
                  <HowToWin data={data} />
                </Columns>
              </Columns.Column>
            </Columns>
            <Questions data={data} />
          </Container>
        </Section>
        <Section>
          <Container className="content">
            <Columns>
              <Columns.Column className="is-10" />
              <BookWorkshop />
              <Commentary data={data} />
            </Columns>
          </Container>
        </Section>
      </div>
    </Layout>
  )
}
StrategyCascade.propTypes = {
  data: PropTypes.object,
}
export default StrategyCascade
