import PropTypes from 'prop-types'
import React from 'react'
import { Columns } from 'react-bulma-components'

function HowToWin({ data }) {
  return (
    <Columns.Column className="is-12 bb br p-5">
      <h3>How to Win</h3>
      <div
        className=""
        dangerouslySetInnerHTML={{
          __html: data.strategy.howToWinNode.childMarkdownRemark.html,
        }}
      />
    </Columns.Column>
  )
}
HowToWin.propTypes = {
  data: PropTypes.object,
}
export default HowToWin
