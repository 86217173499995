import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import { Section, Container, Columns } from 'react-bulma-components'
import { GatsbyImage } from 'gatsby-plugin-image'

function StrategyNav({ data }) {
  return (
    <Section>
      <Container className="container content">
        <Columns className="is-vcentered">
          <Columns.Column className="is-8 is-offset-1">
            <h2 className="subtitle is-size-4">
              <Link to="/mobile-strategy-toolkit" className="has-text-primary">
                <i className="fa-solid fa-arrow-left-long" /> Inspiring Mobile
                Strategies
              </Link>{' '}
            </h2>
            <h1 className="title is-size-2 has-text-dark">
              {data.strategy.headline}
            </h1>
          </Columns.Column>
          <Columns.Column className="is-offset-1 is-1 is-hidden-mobile ">
            <GatsbyImage
              image={data.strategy.brand.logoDark.gatsbyImageData}
              alt={data.strategy.brand.name || 'Pocketworks'}
            />
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}
StrategyNav.propTypes = {
  data: PropTypes.object,
}
export default StrategyNav
