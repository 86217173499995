import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

function StrategySubscribeComponent() {
  const handleFormSubmit = async (e) => {
    e.preventDefault()
    const $form = e.target
    console.log($form.elements.email.value)
    const result = await addToMailchimp($form.elements.email.value)
    if (result && result.result === 'Success') {
      console.log(result)
      alert(result.msg)
    } else {
      alert(result.msg)
    }
  }

  return (
    <div>
      <form id="strategy-form" onSubmit={handleFormSubmit}>
        <div className="field">
          <div className="control has-icons-left has-icons-right">
            <input name="tags" type="hidden" value="6663530" />
            <input
              name="email"
              className="input"
              type="email"
              placeholder="Your email"
            />
            <span className="icon is-small is-left">
              <i className="fas fa-envelope" />
            </span>
            <span className="is-hidden icon is-small is-right">
              <i className="fas fa-exclamation-triangle" />
            </span>
            <div className="control mt-3">
              <button className="button is-primary" type="submit">
                Subscribe
              </button>
              <p className="pt-2 is-size-6 has-text-grey small">
                <small>
                  You can unsubscribe at anytime and we do not sell your data to
                  anyone.
                </small>
              </p>
            </div>
          </div>
          <p className="is-hidden help is-danger">This email is invalid</p>
        </div>
      </form>
    </div>
  )
}

export default StrategySubscribeComponent
