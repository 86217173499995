import PropTypes from 'prop-types'
import React from 'react'
import { Columns } from 'react-bulma-components'
import { GatsbyImage } from 'gatsby-plugin-image'

function WinningInspiration({ data }) {
  return (
    <Columns.Column className="is-6 br bt p-5">
      <GatsbyImage
        image={data.strategy.brand.logoDark.fixedGatsbyImageData}
        alt={data.strategy.brand.name}
        className="is-pulled-right"
      />
      <h3 className="mt-0">Winning Aspiration</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: data.strategy.winningAspirationNode.childMarkdownRemark.html,
        }}
      />
    </Columns.Column>
  )
}
WinningInspiration.propTypes = {
  data: PropTypes.object,
}
export default WinningInspiration
