import PropTypes from 'prop-types'
import React from 'react'
import { Container, Columns, Hero } from 'react-bulma-components'

function StrategyImage({ data }) {
  return (
    <Hero
      className="has-bg-image is-medium blog-hero"
      style={{
        backgroundImage: `url(${
          data.strategy.heroBannerImage
            ? data.strategy.heroBannerImage.fluid.src
            : ''
        }&fm=jpg&h=800&fit=crop&crop=face`,
      }}
      id="blog-hero"
    >
      <Hero.Body>
        <Container className="container content">
          <Columns className="mb-6 pb-6">
            <Columns.Column className="is-offset-0 is-7">
              <br />
              <br />
              <br />
              <br />
              <br />
            </Columns.Column>
            <Columns.Column className="is-2 is-offset-1" />
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  )
}
StrategyImage.propTypes = {
  data: PropTypes.object,
}
export default StrategyImage
