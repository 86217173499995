import PropTypes from 'prop-types'
import React from 'react'
import { Columns } from 'react-bulma-components'

// Components
import AskQuestion from 'components/ask-question'

function Questions({ data }) {
  return (
    <Columns>
      <Columns.Column className="is-12 is-offset-1">
        <AskQuestion
          tag={data.strategy.slug}
          linkClassName="has-text-primary"
        />
      </Columns.Column>
    </Columns>
  )
}
Questions.propTypes = {
  data: PropTypes.object,
}
export default Questions
