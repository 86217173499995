import PropTypes from 'prop-types'
import React from 'react'
import { Columns } from 'react-bulma-components'

function Channel({ data }) {
  return (
    <Columns.Column className="is-6 bt bb br p-5">
      <h3>&nbsp;</h3>
      <h6 className="mt-5">Channel</h6>
      <div
        dangerouslySetInnerHTML={{
          __html: data.strategy.whereToPlayChannelNode.childMarkdownRemark.html,
        }}
      />

      <h6 className="mt-5">Offer</h6>
      <div
        className=""
        dangerouslySetInnerHTML={{
          __html:
            data.strategy.whereToPlayOfferingNode.childMarkdownRemark.html,
        }}
      />
    </Columns.Column>
  )
}
Channel.propTypes = {
  data: PropTypes.object,
}
export default Channel
