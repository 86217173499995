import PropTypes from 'prop-types'
import React from 'react'
import { Columns } from 'react-bulma-components'

function WhereToPlay({ data }) {
  return (
    <Columns.Column className="is-6 bt bb p-5">
      <h3>Where to Play</h3>
      <h6>Geography</h6>
      <div
        dangerouslySetInnerHTML={{
          __html:
            data.strategy.whereToPlayGeographyNode.childMarkdownRemark.html,
        }}
      />

      <h6 className="mt-5">Customer</h6>
      <div
        dangerouslySetInnerHTML={{
          __html:
            data.strategy.whereToPlayCustomersNode.childMarkdownRemark.html,
        }}
      />
    </Columns.Column>
  )
}
WhereToPlay.propTypes = {
  data: PropTypes.object,
}
export default WhereToPlay
