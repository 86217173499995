import React from 'react'
import { Columns } from 'react-bulma-components'

// Components
import StrategySubscribeComponent from 'components/strategy-subscribe'

function Subscribe() {
  return (
    <Columns.Column className="is-4 ml-5">
      <div className="box is-rounded p-6 is-size-6">
        <h3 className="title pb-0 mb-0 pt-0 mt-0">
          Get inspiring strategies,{' '}
          <span className="has-text-primary">straight to your inbox.</span>
        </h3>
        <span className="is-size-6 has-text-grey">
          Learn what works, every month.
        </span>
        <StrategySubscribeComponent />
      </div>
    </Columns.Column>
  )
}

export default Subscribe
